<template>
  <div>
    <familyDetailEdit v-if="isEdit" :data="data" @action="action" />
    <familyDetailView v-else @passData="passData" @action="action" />
  </div>
</template>

<script>
import familyDetailView from "@/components/pages/admin/workers/detail-worker/view/family-detail.vue";
import familyDetailEdit from "@/components/pages/admin/workers/detail-worker/edit/family-detail.vue";

export default {
    components: { familyDetailView, familyDetailEdit },
    data() {
        return {
            isEdit: false,
            data: {},
        };
    },

    methods: {
        action(isEdit) {
            this.isEdit = isEdit;
        },

        passData(data) {
            this.data = data;
        }
    }
};
</script>
