<template>
  <div>
    <section>
        <button
            v-if="$can('EDIT', 'worker-mitra')"
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="$emit('action', true)"
        >
            Edit
        </button>
    </section>
    <section class="uk-margin">
      <div
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
        v-for="(detail, dKey) in details"
        :key="dKey"
      >
        <div class="uk-width-1-2">{{ detail.label }}</div>
        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-auto">:&nbsp;</div>
            <div class="uk-width-expand">
              {{ detail.value === 'couple_birthdate' ? formatter.dateFormatSlash(data[detail.value]) : data[detail.value] }}
            </div>
          </div>
        </div>
      </div>
      <section 
        v-for="(children, cKey) in data.children"
        :key="`c-${cKey}`"
      >
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
          v-for="(detail, dKey) in detailsChildren"
          :key="`d-${dKey}`"
        >
          <div class="uk-width-1-2">{{ `${detail.label} ${cKey > 0 ? '('+cKey + 1+')' : ''}` }}</div>
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <div class="uk-width-auto">:&nbsp;</div>
              <div class="uk-width-expand">
                {{ detail.value === 'birthdate' ? formatter.dateFormatSlash(children[detail.value]) : children[detail.value] }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";

export default {
    data() {
        return {
            isLoading: false,
            details: [
                { label: 'Nama Pasangan', value: 'couple_name' },
                { label: 'Tempat Lahir Pasangan', value: 'couple_birthplace' },
                { label: 'Tanggal Lahir Pasangan', value: 'couple_birthdate' },
                { label: 'Nomor KTP Pasangan', value: 'couple_identity_number' },
                { label: 'Pekerjaan Pasangan', value: 'couple_job' },
            ],
            detailsChildren: [
                { label: 'Nama Anak', value: 'name' },
                { label: 'Tempat Lahir Anak', value: 'birthplace' },
                { label: 'Tanggal Lahir Anak', value: 'birthdate' },
                { label: 'Nomor Identitas Anak (KIA)', value: 'identity_number' },
            ],
            data: null,
            formatter: formatter,
        };
    },

    async mounted() {
        this.getMitraFamilyDetail();
    },

    methods: {
        ...mapActions({
            getFamilyDetail: 'mitra/getFamilyDetail',
        }),

        async getMitraFamilyDetail() {
            this.isLoading = true;
            const data = await this.getFamilyDetail(this.$route.params.mitra_id);

            if (data.result) {
                this.data = data.result.detail.family;
                this.$emit("passData", this.data);
            }

            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
