<template>
  <div>
    <section>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="saveData"
        >
            Save
        </button>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="border-color: #0ABAB5; color: #0ABAB5"
            @click="$emit('action', false)"
        >
            Cancel
        </button>
    </section>
    <section class="uk-margin">
      <div
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
        v-for="(detail, dKey) in details"
        :key="dKey"
      >
        <div class="uk-width-1-2 uk-margin-auto-vertical">{{ detail.label }}</div>
        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <!-- DATE INPUT -->
            <div v-if="detail.type === 'date'" class="uk-width-expand">
              <datetime
                  name="date"
                  id="date"
                  input-class="uk-input uk-border-rounded"
                  type="date"
                  format="dd LLL yyyy"
                  :max-datetime="datetime.now().toISODate()"
                  v-model="form[detail.value]"
              ></datetime>
            </div>
            <!-- TEXT INPUT -->
            <div v-else class="uk-width-expand">
              <input
                v-model="form[detail.value]"
                class="uk-input"
                type="text"
              >
            </div>
          </div>
          <div v-if="errorsD[detail.value]" class="uk-text-danger">{{ errorsD[detail.value] }}</div>
        </div>
      </div>
      <!-- Children Form -->
      <section 
        v-for="(children, cKey) in form.children"
        :key="`c-${cKey}`"
      >
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
          v-for="(detail, dKey) in detailsChildren"
          :key="`d-${dKey}`"
        >
          <div class="uk-width-1-2 uk-margin-auto-vertical">{{ `${detail.label} ${cKey > 0 ? '('+cKey + 1+')' : ''}` }}</div>
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <!-- DATE INPUT -->
              <div v-if="detail.type === 'date'" class="uk-width-expand">
                <datetime
                    name="date"
                    id="date"
                    input-class="uk-input uk-border-rounded"
                    type="date"
                    format="dd LLL yyyy"
                    :max-datetime="datetime.now().toISODate()"
                    v-model="form.children[cKey][detail.value]"
                ></datetime>
              </div>
              <!-- TEXT INPUT -->
              <div v-else class="uk-width-expand">
                <input
                  v-model="form.children[cKey][detail.value]"
                  class="uk-input"
                  type="text"
                >
              </div>
            </div>
            <div v-if="errorsC[cKey] && errorsC[cKey][detail.value]" class="uk-text-danger">{{ errorsC[cKey][detail.value] }}</div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import { notificationSuccess } from '@/utils/notification';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    components: { Datetime },
    data() {
        return {
            datetime: DateTime,
            isLoading: false,
            details: [
                { label: 'Nama Pasangan', value: 'couple_name', type: "text" },
                { label: 'Tempat Lahir Pasangan', value: 'couple_birthplace', type: "text" },
                { label: 'Tanggal Lahir Pasangan', value: 'couple_birthdate', type: "date" },
                { label: 'Nomor KTP Pasangan', value: 'couple_identity_number', type: "text" },
                { label: 'Pekerjaan Pasangan', value: 'couple_job', type: "text" },
            ],
            detailsChildren: [
                { label: 'Nama Anak', value: 'name', type: "text" },
                { label: 'Tempat Lahir Anak', value: 'birthplace', type: "text" },
                { label: 'Tanggal Lahir Anak', value: 'birthdate', type: "date" },
                { label: 'Nomor Identitas Anak (KIA)', value: 'identity_number', type: "text" },
            ],
            cities: [],
            form: { ...this.$props.data },
            errorsD: {},
            errorsC: [{}],
        };
    },

    methods: {
        ...mapActions({
            patchFamilyDetail: 'mitra/patchFamilyDetail',
            getCities: 'option_data/getCities',
        }),

        async saveData() {
            this.isLoading = true;

            if (!this.validateData()) {
                this.isLoading = false;
                return false;
            }

            const nForm = {
                "detail": {
                    "family": {
                        "couple_name": !this.form.couple_name || this.form.couple_name === ""
                            ? null : this.form.couple_name,
                        "couple_birthdate": !this.form.couple_birthdate || this.form.couple_birthdate === ""
                            ? null : this.form.couple_birthdate,
                        "couple_birthplace": !this.form.couple_birthplace || this.form.couple_birthplace === ""
                            ? null : this.form.couple_birthplace,
                        "couple_identity_number": !this.form.couple_identity_number || this.form.couple_identity_number === ""
                            ? null : this.form.couple_identity_number,
                        "couple_job": !this.form.couple_job || this.form.couple_job === ""
                            ? null : this.form.couple_job,
                        "children": this.form.children
                    }
                } 
            };

            await this.patchFamilyDetail({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        },
        
        async getCityOptions(query){
            this.isLoading = true;
            this.cities = await this.getCities({name: query});
            this.isLoading = false;
        },

        validateData() {
            this.errorsD = {};
            this.errorsC = [{}];
            let isValidated = true;
            
            if (this.form.couple_name && !/^[a-zA-Z ]*$/.test(this.form.couple_name)) {
                this.errorsD["couple_name"] = "nama tidak valid";
                isValidated = false;
            }
            
            if (this.form.couple_birthplace && !/^[a-zA-Z ]*$/.test(this.form.couple_birthplace)) {
                this.errorsD["couple_birthplace"] = "tempat lahir tidak valid";
                isValidated = false;
            }
            
            if (this.form.couple_identity_number && !/^\d*$/.test(this.form.couple_identity_number)) {
                this.errorsD["couple_identity_number"] = "nomor identitas tidak valid";
                isValidated = false;
            }
            
            this.form.children.forEach((data, dKey) => {
                if (!data.name) {
                    if (!this.errorsC[dKey]) {
                        this.errorsC[dKey] = { name: "nama harus diisi" };
                    } else {
                        this.errorsC[dKey]["name"] = "nama harus diisi";
                    }
                    
                    isValidated = false;
                }

                if (data.name && !/^[a-zA-Z ]*$/.test(data.name)) {
                    if (!this.errorsC[dKey]) {
                        this.errorsC[dKey] = { name: "nama tidak valid" };
                    } else {
                        this.errorsC[dKey]["name"] = "nama tidak valid";
                    }
                    
                    isValidated = false;
                }

                if (data.birthplace && !/^[a-zA-Z ]*$/.test(data.birthplace)) {
                    if (!this.errorsC[dKey]) {
                        this.errorsC[dKey] = { birthplace: "tempat lahir tidak valid" };
                    } else {
                        this.errorsC[dKey]["birthplace"] = "tempat lahir tidak valid";
                    }
                    
                    isValidated = false;
                }

                if (!data.birthdate) {
                    if (!this.errorsC[dKey]) {
                        this.errorsC[dKey] = { birthdate: "tanggal lahir harus diisi" };
                    } else {
                        this.errorsC[dKey]["birthdate"] = "tanggal lahir harus diisi";
                    }
                    
                    isValidated = false;
                }

                if (!data.birthplace) {
                    if (!this.errorsC[dKey]) {
                        this.errorsC[dKey] = { birthplace: "tempat lahir harus diisi" };
                    } else {
                        this.errorsC[dKey]["birthplace"] = "tempat lahir harus diisi";
                    }
                    
                    isValidated = false;
                }

                if (data.birthplace && !/^[a-zA-Z ]*$/.test(data.birthplace)) {
                    if (!this.errorsC[dKey]) {
                        this.errorsC[dKey] = { birthplace: "tempat lahir tidak valid" };
                    } else {
                        this.errorsC[dKey]["birthplace"] = "tempat lahir tidak valid";
                    }
                    
                    isValidated = false;
                }

                if (!data.identity_number) {
                    if (!this.errorsC[dKey]) {
                        this.errorsC[dKey] = { identity_number: "nomor identitas harus diisi" };
                    } else {
                        this.errorsC[dKey]["identity_number"] = "nomor identitas harus diisi";
                    }
                    
                    isValidated = false;
                }

                if (data.identity_number && !/^\d*$/.test(data.identity_number)) {
                    if (!this.errorsC[dKey]) {
                        this.errorsC[dKey] = { identity_number: "nomor identitas tidak valid" };
                    } else {
                        this.errorsC[dKey]["identity_number"] = "nomor identitas tidak valid";
                    }
                    
                    isValidated = false;
                }
            });

            return isValidated ? true : false;
        },
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
